import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, graphql } from "gatsby";
import '../../styles/Article.css'
import { DefaultGtag } from '../../utils/misc'


const mainStyles = {
    display: "flex",
    flexDirection: "column"
}

const ArticlePage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
    }, [])

    return (
        <Layout>
            <main style={mainStyles}>
                <div className="blogpage">
                    <div className="our-blogs">
                        <h1 className="title">Articles</h1>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }} className="articles-wrapper">
                            <div>
                                <Link to='/article/5-steps-to-grow-a-restaurant/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/growth.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>5 Steps to grow a restaurant</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/should-my-restaurant-use-digital-menus/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/go-digital.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>Should my restaurant use digital menus?</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/how-to-cope-with-inflation-part-1/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/dollar-burning.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>How to cope with inflation, Part 1</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/5-things-to-consider-when-creating-digital-menus/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/things-to-consider.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>5 things to consider when creating digital menus</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/qr-code-vs-pdf-menu/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/scan-qrcode-2.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>Top 5 benefits of using QR code menus vs PDF</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/checklist-to-open-a-restaurant/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/open-sign.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>The high-level checklist to open a restaurant</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/how-to-market-your-restaurant/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/marketing-your-restaurant.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>How to market your restaurant</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/what-to-include-on-your-restaurant-website/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/keyboard-gab90d7ee9_640.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>What to include on your restaurant website</div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/article/what-makes-a-great-restaurant/'>
                                    <div className="blog-thumbnail">
                                        <div style={{ background: 'url("/static/media/images/board-g1be7417e1_1280.jpg") no-repeat center/cover' }} className="blog-img"></div>
                                        <div className='blog-title'>What makes a great restaurant</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout >
    )
}

export default ArticlePage

export const Head = () => <>
    <DefaultGtag />
    <link rel="canonical" href="https://takeaseat.io/article/" />
    {/* <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/article/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/article/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/article/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/article/" /> */}
    <title>Take a Seat Blog - Digital tools and tips for modern restaurants</title>
    <meta name="description" content="Articles about the restaurant industry and modern tools for restaurants." />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
    <meta property="og:site_name" content="Blog of Take a Seat" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
    <meta name="twitter:site" content="@Takeaseat_io" />
    <meta name="twitter:creator" content="@Takeaseat_io" />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;